
const getNavigatorData = () => {
  return {
    appCodeName: window.navigator.appCodeName,
    appName: window.navigator.appName,
    appVersion: window.navigator.appVersion,
    cookieEnabled: window.navigator.cookieEnabled,
    cpuClass: window.navigator.cpuClass,
    geolocation: window.navigator.geolocation,
    language: window.navigator.language,
    onLine: window.navigator.onLine,
    oscpu: window.navigator.oscpu,
    platform: window.navigator.platform,
    product: window.navigator.product,
    productSub: window.navigator.productSub,
    vendor: window.navigator.vendor,
    userAgent: window.navigator.userAgent
  };
};


export const inspectBrowser = async() => {
  let deviceData = "unavailable"
  // try {
  //   deviceData = await composeDeviceData();
  // }
  // catch {}
  let navigatorData = "unavailable"
  try {
    navigatorData = getNavigatorData();
  }
  catch {}
  return {
    deviceData,
    navigatorData
  };
};
